/**
 * Default environment variable
 * apiBaseUrl: API routes base URL
 * jwtWhitelistedDomains: domain to which JWT token is allowed to be sent
 * blacklistedRoutes: route belonging to allowed domain but JWT token will not be sent to it (login route)
 */
export const environment = {
    production: true,
    apiBaseUrl: 'https://api-staging-authentication.agilog.net',
    jwtWhitelistedDomains: ['api-staging-authentication.agilog.net'],
    blacklistedRoutes: ['https://api-staging-authentication.agilog.net/login'],
    recaptcha: {
        siteKey: '6Ld3f_onAAAAAMWa5Iun61HU-NtHeBk2bA55z5mZ',
    },
    mongoChartUrl: 'https://charts.mongodb.com/charts-agi-log-eankw',
    mongoChartLoadDataFrom: 'staging',
    listRefreshIntervalInMs: 10000,
};
